<template>
  <canvas v-if="canvasView" ref="love" :width="canvasWidth" :height="canvasHeight" />
</template>

<script>
import paper from 'paper';
import axios from 'axios';

const MAX_TEMPLATES = 12;
const logoTags = ['love'];
const MAX_TAGS = 100;

export default {
  name: 'TheIronovImage',
  data: () => ({
    templateNumber: null,
    randomTemplate: null,
    randomTag: null,
    canvasView: false,
    canvasWidth: 582,
    canvasHeight: 449,
    postcardSVG: null,
    postcardPNG: null,
    postCardImage: null,
  }),
  mounted() {
    this.$nextTick(() => this.startDraw());
  },
  methods: {
    getRandomTag() {
      return new Promise((resolve, reject) => {
        const randomTag = logoTags[Math.floor(Math.random() * logoTags.length)];
        const randomTagNumber = 1 + Math.floor(Math.random() * MAX_TAGS);
        axios.get(require(`@/assets/tags/${randomTag}/sample-${randomTagNumber}.svg`)).then(response => resolve(response.data)).catch(error => reject(error));
      });
    },
    getRandomTemplate() {
      return new Promise((resolve, reject) => {
        const randomTemplateNumber = this.templateNumber ? this.templateNumber : Number(1 + Math.floor(Math.random() * MAX_TEMPLATES));
        axios.get(require(`@/assets/templates/svg/${randomTemplateNumber}.svg`)).then(response => resolve(response.data)).catch(error => reject(error));
      });
    },
    async startDraw() {
      try {
        this.randomTag = await this.getRandomTag();
        this.randomTemplate = await this.getRandomTemplate();
        this.canvasView = true;
        await this.createPostCard();
        this.postCardImage = `data:image/svg+xml;base64,${window.btoa(this.postcardSVG)}`;
        this.canvasView = false;
        this.$emit('finish', {
          image: this.postCardImage,
          svg: this.postcardSVG,
          png: this.postcardPNG,
        })
      } catch (e) {
        console.log(e);
      }
    },
    createPostCard() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          paper.setup(this.$refs.love);
          const box = paper.project.view;
          const template = paper.project.importSVG(this.randomTemplate);
          template.clipped = false;
          const logoLove = paper.project.importSVG(this.randomTag);
          logoLove.clipped = false;

          let groupLove;
          groupLove = template.getItem({ match: item => item.className === 'Path' });
          if (groupLove) {
            logoLove.fitBounds(groupLove.bounds);
            logoLove.fillColor = groupLove.fillColor;
            groupLove.remove();
          }
          const size = box.size.height;
          if (logoLove.bounds.height > logoLove.bounds.width) {
            logoLove.bounds.width *= size / logoLove.bounds.height;
            logoLove.bounds.height = size;
          } else {
            logoLove.bounds.height *= size / logoLove.bounds.width;
            logoLove.bounds.width = size;
          }
          logoLove.scale(0.6);
          logoLove.position = new paper.Point(box.center.x, box.center.y);
          logoLove.bringToFront();

          const randomRasterNumber = this.templateNumber ? this.templateNumber : Number(1 + Math.floor(Math.random() * MAX_TEMPLATES));
          const sourceRasterURL = require(`@/assets/templates/png/${randomRasterNumber}.png`);


          const getImageRaster = src => new Promise((resolve) => {
            const image = new Image();
            image.src = src;
            image.width = this.canvasWidth;
            image.height = this.canvasHeight;

            image.onload = () => {
              const raster = new paper.Raster(image);
              raster.scale(this.canvasWidth / Math.max(raster.width, raster.height));
              raster.onLoad = () => setTimeout(() => resolve(raster), 0);
            };
          });
          getImageRaster(sourceRasterURL).then((raster) => {
            raster.position.x = box.center.x;
            raster.position.y = box.center.y;
            raster.sendToBack();
            this.postcardSVG = paper.project.exportSVG({ asString: true });
            setTimeout(() => {
              this.postcardPNG = this.$refs.love.toDataURL('image/png');
              setTimeout(() => resolve(), 100);
            }, 1000);
          });
        });
      });
    },
  },
}
</script>

<style scoped lang="scss">
canvas {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
</style>