<template>
  <div></div>
</template>

<script>
import axios from 'axios';

const CLIENT_ID = 'dfdd40d07e9d968';
// const CLIENT_SECRET = 'cc83088d0a9842cd5589dddb1c02b4f57fc796b0';

export default {
  name: 'TheIronovMail',
  props: {
    form: {
      type: Object,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    linkImage: null,
    domain: 'ironov',
  }),
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      if (window && window.location && window.location.href) {
        if (String(window.location.href).indexOf('ironov-love') > -1) {
          this.domain = 'studio';
        }
      }
      this.sendIronovMail();
    });
  },
  methods: {
    createFile() {
      return new Promise((resolve) => {
        const fileName = `ironov-love-${this.makeId(6)}.png`;
        const uriBlob = this.dataURItoBlob(this.image.png);
        resolve({
          file: uriBlob,
          fileName,
        });
      });
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:mimeString});
    },
    makeId(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    serveOnImgur() {
      return new Promise((resolve) => {
        const formData = new FormData()
        formData.append('image', this.dataURItoBlob(this.image.png));
        fetch("https://api.imgur.com/3/image/", {
          method: "post",
          headers: {
            Authorization: `Client-ID ${CLIENT_ID}`
          },
          body: formData
        }).then(data => data.json()).then(response => {
          if (response.data && response.data) {
            resolve(response.data.link);
          }
        });
      });
    },
    async sendIronovMail() {
      const cardFile = await this.createFile();
      this.linkImage = await this.serveOnImgur();
      const formData = new FormData();
      const innerText = this.formatHTML();
      console.log(this.linkImage);

      formData.append('to', this.form.email);
      formData.append('subject', 'Тебе пришла валентинка');
      formData.append('text', innerText);
      formData.append('file', cardFile.file, cardFile.fileName);

      setTimeout(() => {
        axios({
          method: 'post',
          url: '/to/',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then(() => setTimeout(() => this.$emit('send'), 0))
            .catch(() => setTimeout(() => this.$emit('error'), 0));
      }, 0);
    },
    formatHTML() {
      return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">
<head>
<meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
<meta content="IE=edge" http-equiv="X-UA-Compatible"/>
<meta content="width=device-width, initial-scale=1.0" name="viewport"/>
<meta content="telephone=no" name="format-detection"/>
<title>Тебе пришла валентинка</title>
<style type="text/css">
      *[x-apple-data-detectors=true] {
          color: inherit !important;
          text-decoration: inherit !important;
      }
      p.extra.last a span {
          border-bottom: #333333 1px solid;
      }
      @media (max-width: 600px) {
          h2 {
              font-size: 27px !important;
              margin-bottom: 16px !important;
          }
          p {
              font-size: 16px !important;
          }
          h2, p {
              padding-right: 0 !important;
          }
          a {
              font-size: 16px !important;
          }
          a.link2 {
              font-size: 12px !important;
          }
          p.extra {
              margin-bottom: 16px !important;
          }
          p.extra.last {
              margin-bottom: 0 !important;
          }
          p.extra.last a {
              display: block !important;
          }
          span {
              white-space: nowrap !important;
          }
          ol > li,
          ol {
            font-size: 16px !important;
          }
      }
  </style>
</head>
<body bgcolor="#000000" style="background: #000000; margin: 0; padding: 0;">
<table cellpadding="0" cellspacing="0" style="border-collapse: collapse; padding: 0; margin: 0; font-family: Helvetica, Arial, sans-serif;" width="100%">
<tr>
<td bgcolor="#000000">
<center>
<table class="content" style="background-color: #000000; color: #FFFFFF; width: 100%; margin: 0 auto; max-width: 767px;" width="100%">
<tr>
<td bgcolor="#000000" style="padding: 16px;">
<table class="content" style="background-color: #000000; color: #FFFFFF; width: 100%; margin: 0 auto;  max-width: 767px;" width="100%">
<tr>
<td>
<img src="${this.linkImage}" alt="валентинка" style="display: block; width: 100%; margin: 0; padding: 0;" width="767"/>
<h2 style="margin: 26px 0 0; padding-right: 18%; line-height: 1; font-size: 32px;">${this.form.message}</h2>
<div style="margin: 32px 0">
<!--[if mso]>
                      <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://ironov-love.design.ru/" style="v-text-anchor: middle; width: 767px; height: 168px;" arcsize="10%" strokecolor="#FD4820" fillcolor="#FD4820">
                        <w:anchorlock/>
                        <center>
                      <![endif]-->
<a class="button" href="${this.domain === 'ironov' ? 'https://love.ironov.me/' : 'https://ironov-love.design.ru/'}" style="background-color: #ff00a8; border-radius: 12px; color: #FFF; display: block; padding: 3% 0; text-decoration: none; text-align: center; font-size: 22px; font-weight: 700;"><nobr>Отправить</nobr> <nobr>валентинку</nobr></a>
<!--[if mso]>
                      </center>
                      </v:roundrect>
                      <![endif]-->
</div>
<p style="color: #808080; font-size: 16px; margin-bottom: 24px; line-height: 1.5;"> <nobr>Создано</nobr> <nobr>Николаем</nobr> <nobr>Ироновым</nobr> <br><a style="text-decoration: underline; color: #808080;" href="https://ironov.artlebedev.ru/?utm_source=love&utm_medium=email&utm_campaign=love">ironov.artlebedev.ru</a><br><a style="text-decoration: underline; color: #808080;" href="https://www.instagram.com/ironov.design/">instagram.com/ironov.design</a></p>
</td>
</tr>
</table>
</td>
</tr>
</table>
</center>
</td>
</tr>
</table>
</body>
</html>`;
    },
  },
}
</script>

<style scoped lang="scss">
</style>