<template>
  <div id="app">
    <the-ironov-form v-if="!ironovMessage" @send="sendCard" />
    <the-ironov-message v-else :message="ironovMessage" :finish.sync="againLove" />
    <the-ironov-image v-if="getImage" @finish="finish" />
    <the-ironov-mail v-if="cardImage && formData" :image="cardImage" :form="formData" @send="finishSend" @error="errorSend" />
  </div>
</template>

<script>
import TheIronovForm from './components/TheIronovForm';
import TheIronovMessage from './components/TheIronovMessage';
import TheIronovImage from './components/TheIronovImage';
import TheIronovMail from './components/TheIronovMail';

export default {
  name: 'ironov-love',
  components: {
    TheIronovForm,
    TheIronovMessage,
    TheIronovImage,
    TheIronovMail,
  },
  data: () => ({
    ironovMessage: null,
    getImage: false,
    cardImage: null,
    formData: null,
    againLove: false,
  }),
  created() {
    document.title = 'Иронов: Любовь';
  },
  mounted() {
  },
  methods: {
    sendCard(payload) {
      this.formData = payload;
      this.getImage = true;
      this.ironovMessage = 'Секундочку. Генерирую валентинку';
    },
    finish(payload) {
      this.getImage = false;
      this.cardImage = payload;
    },
    finishSend() {
      this.ironovMessage = 'Валентинка отправлена!';
      this.getImage =  false;
      this.cardImage = null;
      this.formData = null;
      setTimeout(() => { this.againLove = true; }, 0);
    },
    errorSend() {
      this.ironovMessage = 'Я наверное устал и не смог отправить валентинку :(';
      setTimeout(() => { this.againLove = true; }, 0);
    },
  },
}
</script>

<style>
</style>
